
import {
    HttpClient,
    HttpEvent,
    HttpHeaders, HttpParams,
    HttpResponse
} from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from 'src/applazy/shared/models/configuration';
import { CustomHttpUrlEncodingCodec } from 'src/applazy/shared/models/encoder';
import { environment } from 'src/environments/environment';
import { PaginatedItemsReadModelOfIconReadModel } from './imageclouddata.service';


@Injectable({
    providedIn: 'root'
})
export class IconService {

    protected basePath = environment.imageCloud;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || this.basePath;

        } else {
            this.configuration.basePath = this.basePath;
        }
    }

    public apiVapiVersionIconGetIconsGet(apiVersion: string, searchText?: string, pageIndex?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<PaginatedItemsReadModelOfIconReadModel>;
    public apiVapiVersionIconGetIconsGet(apiVersion: string, searchText?: string, pageIndex?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaginatedItemsReadModelOfIconReadModel>>;
    public apiVapiVersionIconGetIconsGet(apiVersion: string, searchText?: string, pageIndex?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaginatedItemsReadModelOfIconReadModel>>;
    public apiVapiVersionIconGetIconsGet(apiVersion: string, searchText?: string, pageIndex?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiVapiVersionIconGetIconsGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('searchText', <any>searchText);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('pageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PaginatedItemsReadModelOfIconReadModel>(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/Icon/GetIcons`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
