export const apiUrl = 'https://apis.bonee.net';

export const environment = {
  production: true,
  googleApiKey: 'AIzaSyAr41VLC5BBZSEwJVbWIMF130oXG5ubVlg',
  googleLocationUrl: 'https://maps.googleapis.com/maps/api/geocode/json',
  appInsightsInstrumentationKey: 'fa2bbb3f-11dd-4357-bae7-675e06da5d26',
  originURL: 'https://portal.bonee.net',
  wellknown: '/.well-known/openid-configuration',
  // identityUrl         : `${apiUrl}/identity`,
  identityUrl: 'https://accounts.bonee.net',
  catalogServicesUrl: `${apiUrl}/catalog`,
  basket: `${apiUrl}/basket`,
  clientaggregator: `${apiUrl}/httpaggregator`,
  translationUrl: `${apiUrl}/translation`,
  filestoreUrl: `${apiUrl}/filestore`,
  globalizationUrl: `${apiUrl}/globalization`,
  userproviderUrl: `${apiUrl}/userprovider`,
  feedback: `${apiUrl}/feedback`,
  partnerUrl: `${apiUrl}/partner`,
  employeeUrl: `${apiUrl}/employee`,
  orderUrl: `${apiUrl}/order`,
  paymentUrl: `${apiUrl}/payment`,
  hotactions: `${apiUrl}/hotactions`,
  dataprocessing: `${apiUrl}/dataprocessing`,
  marketplace: `${apiUrl}/marketplace`,
  webhook: `${apiUrl}/webHookUrl`,
  smartTranslate: `${apiUrl}/smartTranslate`,
  imageCloud: `${apiUrl}/imageCloud`,
  subscriptionsUrl: `${apiUrl}/subscriptions`,
  signalRUrl: `${apiUrl}/signalr`,
  ordering: `${apiUrl}/order`,
  talkUrl: `${apiUrl}/talkUrl`,
  loyaltyServicesUrl: `${apiUrl}/loyality`,
  templateGeneratorUrl: `${apiUrl}/templateGenerator`,
  cardUrl: `${apiUrl}/card`,
  advertisementUrl: `${apiUrl}/advertisement`,
  clientUrl: `${apiUrl}/client`,
  integration: `${apiUrl}/integration`,
  salePortalUrl: `${apiUrl}/saleportal`,
  externalIntegration: `${apiUrl}/integration`,
  theme: `${apiUrl}/style`,
  webhookDocumentation: 'https://webhook.bonee.net',
  companyType: `${apiUrl}/userdefaultsettings`,
  couponUrl: `${apiUrl}/loyality`,
  posUrl: `${apiUrl}/pos`
};

export const defaultdata = {
  noneimageurl: '../../../../assets/images/svg/main/default-image.svg'
};
