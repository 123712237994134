import {
    HttpClient,
    HttpEvent,
    HttpHeaders, HttpParams,
    HttpResponse
} from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from 'src/applazy/shared/models/configuration';
import { CustomHttpUrlEncodingCodec } from 'src/applazy/shared/models/encoder';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class PaymentSystemsService {

    protected basePath = environment.paymentUrl;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || this.basePath;

        } else {
            this.configuration.basePath = this.basePath;
        }
    }

    public apiV1PaymentSystemsPartnerSystemsGet(apiVersion: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PaymentGroupReadModel>>;
    public apiV1PaymentSystemsPartnerSystemsGet(apiVersion: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaymentGroupReadModel>>>;
    public apiV1PaymentSystemsPartnerSystemsGet(apiVersion: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaymentGroupReadModel>>>;
    public apiV1PaymentSystemsPartnerSystemsGet(apiVersion: string, userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1PaymentSystemsPartnerSystemsGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        if (userId) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<Array<PaymentGroupReadModel>>(`${this.configuration.basePath}/api/v1/PaymentSystems/team/systems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiV1PaymentSystemsSalePortalSystemsGet(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PaymentGroupReadModel>>;
    public apiV1PaymentSystemsSalePortalSystemsGet(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaymentGroupReadModel>>>;
    public apiV1PaymentSystemsSalePortalSystemsGet(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaymentGroupReadModel>>>;
    public apiV1PaymentSystemsSalePortalSystemsGet(apiVersion: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1PaymentSystemsSalePortalSystemsGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<Array<PaymentGroupReadModel>>(`${this.configuration.basePath}/api/v1/PaymentSystems/saleportal/systems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}

export interface PaymentGroupReadModel {
    paymentSystem?: string;
    pictureUri?: string;
    paymentReadModels?: Array<PaymentReadModel>;
}
export interface PaymentReadModel {
    paymentMethod?: string;
    autoRenew?: boolean;
    pictureUri?: string;
}
