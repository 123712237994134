import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router) {

  }
  canActivate(): Observable<boolean> | boolean {

    return this.authService.authorized.pipe(map(b => {
      if (b === true) {
        return true;
      } else {
        window.history.back();
        return false;
      }
    }));
  }

  canActivateChild() {
    return this.authService.authorized;
  }
}
