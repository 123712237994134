import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DatepickerModule } from '../datepicker/datepicker.module';
import { DirectTranslationService, TranslationService } from '../generatedmodules/translation/api';
import { DomSanitizerDirective } from '../sanitizer/directives/domSanitizer.directive';
import { SanitizerModule } from '../sanitizer/sanitizer.module';
import { AuthService } from '../services/auth.service';
import { CredentialsInterceptor } from '../services/credentials.iterceptor';
import { JwtInterceptor } from '../services/jwt.interceptor';
import { LoaderInterceptor } from '../services/loader.interceptor';
import { StorageService } from '../services/storage.service';
import { TextEditorModule } from '../textEditor/textEditor.module';
import { AccordionListComponent } from './components/accordion-list/accordion-list.component';
import { AddInputComponent } from './components/add-input/add-input.component';
import { AppSwitcherComponent } from './components/app-switcher/app-switcher.component';
import { TranslateEditorComponent } from './components/app-translate-editor/translate-editor.component';
import { CardItemComponent } from './components/card-item/card-item.component';
import { CheckoutFormItemComponent } from './components/checkout-form/checkout-form-item/checkout-form-item.component';
import { CheckoutFormComponent } from './components/checkout-form/checkout-form.component';
import { CheckoutModalComponent } from './components/checkout-modal/checkout-modal.component';
import { DropListComponent } from './components/droplist/droplist.component';
import { DropListService } from './components/droplist/services/droplist.service';
import { DroptreeComponent } from './components/droptree/droptree.component';
import { DropTreeSubscriptionsService } from './components/droptree/services/droptreeSubscriptions.service';
import { MapComponent } from './components/google-map/app-map.component';
import { MapDrawComponent } from './components/google-map/draw/app-map-draw.component';
import { MapInputComponent } from './components/google-map/input/app-map-input.component';
import { CustomMapsAPILoader } from './components/google-map/loader/customMapsApiLoader';
import { MapPolygonComponent } from './components/google-map/polygon/app-map-polygon.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageCropperModalComponent } from './components/image-cropper-modal/app-image-cropper-modal.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { ImagesGalleryModalComponent } from './components/images-gallery-modal/images-gallery-modal.component';
import { ImagesComponent } from './components/images/images.component';
import { LanguagePreferenceComponent } from './components/language-preference/language-preference.component';
import { LanguagesComponent } from './components/languages/languages.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MainGalleryFullscreenComponent } from './components/main-gallery-fullscreen/main-gallery-fullscreen.component';
import { MainGalleryImagesComponent } from './components/main-gallery-images/main-gallery-images.component';
import { MessageboxComponent } from './components/messagebox/messagebox.component';
import { NavigationMenuComponent } from './components/navigation/menu/navigation-menu.component';
import { NotificationBaseComponent } from './components/notification-base/notification-base.component';
import { OrderStatusInfoComponent } from './components/order-status-info/order-status-info.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SecurityComponent } from './components/security/security.component';
import { SideBarListComponent } from './components/sidebar-list/sidebar-list.component';
import { SideBarComponent } from './components/sidebar/sidebar.component';
import { TablesComponent } from './components/tables/tables.component';
import { TimesGroupComponent } from './components/times/group/times-group.component';
import { TimesItemComponent } from './components/times/times-item/times-item.component';
import { TimesComponent } from './components/times/times.component';
import { ToppanelComponent } from './components/toppanel/toppanel.component';
import { ClickOutsideDirective } from './directives/clickoutside.directive';
import { FloatPipe } from './directives/float.directive';
import { FormFirstInvalidInputFocusDirective } from './directives/formFirstInvalidInputFocus.directive';
import { KeyWordDirective } from './directives/keyword.directive';
import { NumberOnlyRegDirective } from './directives/numberonlyreg.directive';
import { PagechangeDirective } from './directives/pageChange.directive';
import { ResizableDirective } from './directives/resizeColumn.directive';
import { RtlLangDirective } from './directives/rtlLang.directive';
import { ScrollDataDirective } from './directives/scrolldata.directive';
import { UserRolesDirective } from './directives/userroles.directive';
import { LazyFakeMissingTranslationHandler } from './handlers/lazyFakeMissingTranslationHandler';
import { TranslationLoader } from './loaders/translationloader';
import { KeysPipe } from './pipes/KeysPipe';
import { SafePipe } from './pipes/SafePipe';
import { DateFormatPipe } from './pipes/dataformatPipe';
import { ImageSizePipe } from './pipes/imagesize.pipe';
import { LazyTranslatePipe } from './pipes/lazy-translate.pipe';
import { LinkPipe } from './pipes/link.pipe';
import { UTCDatePipe } from './pipes/utcdate.pipe';
import { GlobalizationService } from './services/globalization.service';
import { HelperService } from './services/helper.service';
import { ImageStoreDataService } from './services/imageStoreData.service';
import { ImageStoreService } from './services/imagestore/imageStore.service';
import { TemporaryImagesService } from './services/imagestore/temporaryImages.service';
import { StoreMarketPlaceOrderService } from './services/marketplace/storeMarketPlaceOrder.service';
import { NavigationService } from './services/navigation.service';
import { OrderStatusInfoService } from './services/order/orderstatusinfo.service';
import { SalePortalInfoService } from './services/salePortalInfo.service';
import { SalePortalInfoDataService } from './services/saleportalinfodata.service';
import { SecurityService } from './services/security.service';
import { UserLocationService } from './services/userlocation.service';
import { EqualValidator } from './validators/custom_validators';
import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component';

export function HttpLoaderFactory(storageService: StorageService, translationService: TranslationService, directTranslationService: DirectTranslationService, authService: AuthService) {
  return new TranslationLoader(storageService, translationService, directTranslationService, authService);
}

@NgModule({
  declarations: [
    DateFormatPipe,
    ImageSizePipe,
    KeysPipe,
    LazyTranslatePipe,
    SafePipe,
    UTCDatePipe,

    MessageboxComponent,
    LoaderComponent,
    TimesItemComponent,
    TimesComponent,
    TimesGroupComponent,
    NavigationMenuComponent,
    ToppanelComponent,
    LanguagesComponent,
    LanguagePreferenceComponent,
    SecurityComponent,
    MapComponent,
    MapInputComponent,
    MapDrawComponent,
    MapPolygonComponent,
    ImagesComponent,
    ImageCropperComponent,
    ImageCropperModalComponent,
    ImagesGalleryModalComponent,
    TablesComponent,
    ThemeSwitcherComponent,
    HeaderComponent,
    DropListComponent,
    DroptreeComponent,
    AccordionListComponent,
    CardItemComponent,
    CheckoutFormComponent,
    CheckoutFormItemComponent,
    NotificationBaseComponent,
    SideBarComponent,
    SideBarListComponent,
    MainGalleryImagesComponent,
    MainGalleryFullscreenComponent,
    PageNotFoundComponent,
    AddInputComponent,
    CheckoutModalComponent,
    OrderStatusInfoComponent,
    TranslateEditorComponent,
    FloatPipe,
    LinkPipe,
    NumberOnlyRegDirective,
    PagechangeDirective,
    FormFirstInvalidInputFocusDirective,
    RtlLangDirective,
    UserRolesDirective,
    ScrollDataDirective,
    ClickOutsideDirective,
    KeyWordDirective,
    ResizableDirective,

    EqualValidator,
    AppSwitcherComponent
  ],
  exports: [
    FormsModule,
    ImageCropperModule,
    AgmCoreModule,
    TranslateModule,
    InfiniteScrollModule,
    ColorPickerModule,
    DatepickerModule,
    SanitizerModule,
    DateFormatPipe,
    ImageSizePipe,
    KeysPipe,
    LazyTranslatePipe,
    SafePipe,
    UTCDatePipe,

    MessageboxComponent,
    LoaderComponent,
    TimesItemComponent,
    TimesComponent,
    TimesGroupComponent,
    NavigationMenuComponent,
    ToppanelComponent,
    LanguagesComponent,
    LanguagePreferenceComponent,
    SecurityComponent,
    MapComponent,
    MapInputComponent,
    MapDrawComponent,
    MapPolygonComponent,
    ImagesComponent,
    ImageCropperComponent,
    ImageCropperModalComponent,
    ImagesGalleryModalComponent,
    TablesComponent,
    ThemeSwitcherComponent,
    HeaderComponent,
    DropListComponent,
    DroptreeComponent,
    AccordionListComponent,
    CardItemComponent,
    CheckoutFormComponent,
    CheckoutFormItemComponent,
    NotificationBaseComponent,
    SideBarComponent,
    SideBarListComponent,
    MainGalleryImagesComponent,
    MainGalleryFullscreenComponent,
    PageNotFoundComponent,
    AddInputComponent,
    CheckoutModalComponent,
    OrderStatusInfoComponent,
    TranslateEditorComponent,
    AppSwitcherComponent,
    FloatPipe,
    LinkPipe,
    NumberOnlyRegDirective,
    PagechangeDirective,
    FormFirstInvalidInputFocusDirective,
    RtlLangDirective,
    UserRolesDirective,
    ScrollDataDirective,
    ClickOutsideDirective,
    ResizableDirective,
    KeyWordDirective,
    DomSanitizerDirective,

    EqualValidator
  ],
  providers: [
    HelperService,
    NavigationService,
    ImageStoreDataService,
    ImageStoreService,
    TemporaryImagesService,
    GlobalizationService,
    DropTreeSubscriptionsService,
    DropListService,
    NavigationService,
    OrderStatusInfoService,
    SecurityService,
    UserLocationService,
    SalePortalInfoService,
    SalePortalInfoDataService,
    StoreMarketPlaceOrderService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: MapsAPILoader, useClass: CustomMapsAPILoader },
    { provide: MissingTranslationHandler, useClass: LazyFakeMissingTranslationHandler }
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ImageCropperModule,
    ColorPickerModule,
    InfiniteScrollModule,
    DatepickerModule,
    TextEditorModule,
    SanitizerModule,
    AgmCoreModule.forRoot({
      libraries: ['places', 'drawing'],
      language: 'ru',
      region: 'ru'
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [StorageService, TranslationService, DirectTranslationService, AuthService]
      }
    }),
    HttpClientModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
}
