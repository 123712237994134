import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorsService } from '../shared/services/errors.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  allowAnimate = false;
  private _ignoreApis = [environment.signalRUrl];
  constructor(
    private loaderService: LoaderService,
    private errorsService: ErrorsService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isPost = req.method.toLowerCase() === 'post' || req.method.toLowerCase() === 'put';
    if (this._ignoreApis.findIndex(api => req.url.startsWith(api)) === -1) {
      this.showLoader(isPost);
    } else {
    }
    return next.handle(req).pipe(
      catchError(this.onCatch),
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.onSuccess(event);
          }
        },
        (err: any) => {
          this.onError(err);
        }
      ),
      finalize(() => {
        this.onEnd();
      })
    );
  }
  private onCatch(error: any, caught: Observable<any>): Observable<any> {
    return observableThrowError(error);
  }

  private onSuccess(res: HttpResponse<any>): void {
    console.log('Request successful');
  }

  private onError(res: HttpResponse<any>): void {
    this.errorsService.addError({ code: res.status });
    console.log('Error, status code: ' + res.status);
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(isPost: boolean): void {
    this.allowAnimate = true;
    this.loaderService.show(isPost);
  }

  private hideLoader(): void {
    this.allowAnimate = false;
    this.loaderService.hide();
  }
}
